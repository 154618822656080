import { axios } from '@slg/web-customer-shared';

const apiDomain = 'abs-retailer/api/v1/retailer/user';

const publicDomain = 'abs-retailer/public/v1/retailer/user';

const logIn = (login, password) => axios.post(`${publicDomain}/login`, { login, password });

const getProfile = () => axios.get(`${apiDomain}/profile`);

export default { logIn, getProfile }
