const mutations = {
  setBookings(state, data) {
    state.bookings = data.retailerBookingsDTOList;
    state.totalCount = data.totalCount;
  },
  setPagination(state, data) {
    if (data.itemsPerPage) {
      state.limit = data.itemsPerPage;
    }
    state.offset = data.pageStart;
  },
  updateLoading(state, data) {
    state.loading = data;
  },
  updateCancelBookingDialog(state, data) {
    state.cancelBookingDialog = data;
  },
  updateEditBookingDialog(state, data) {
    state.editBookingDialog = data;
  },
  updateSortParams(state, data) {
    state.sortParams[data.field] = data.value;
  },
  updateSortField(state, data) {
    state.sortField = data;
  },
  setOriginalBookingDetails(state, data) {
    state.originalBookingDetails = data;
  },
  updateNumericFilter(state, data) {
    state.numericFilters = data.length
      ? [
        {
          fieldName: 'bookingDateTime',
          operationType: 'GTE',
          value: data[0],
        },
        {
          fieldName: 'bookingDateTime',
          operationType: 'LTE',
          value: data[1],
        },
      ] : []
  },
  updateStringFilter(state, data) {
    if (data.value) {
      const filter = state.stringFilters.find((i) => i.fieldName === data.fieldName);
      if (filter) {
        filter.values = [data.value];
      } else {
        state.stringFilters.push({
          exactMatch: false,
          fieldName: data.fieldName,
          values: [data.value],
        })
      }
    } else {
      state.stringFilters = state.stringFilters.filter((i) => i.fieldName !== data.fieldName);
    }
  },
  clearFilters(state) {
    state.numericFilters = [];
    state.stringFilters = [];
    state.sortField = 'tripDate';
    state.sortParams.descending = true;
    state.sortParams.ignoreCaseSort = true;
  },
  updateBookingForCancel(state, data) {
    if (data) {
      state.bookingForCancel.firstName = data.mainPassengerDetails.firstName;
      state.bookingForCancel.lastName = data.mainPassengerDetails.lastName;
      state.bookingForCancel.tripDate = data.tripDate;
      state.bookingForCancel.retailerBookingId = data.retailerBookingId;
    } else {
      state.bookingForCancel.firstName = '';
      state.bookingForCancel.lastName = '';
      state.bookingForCancel.tripDate = '';
      state.bookingForCancel.retailerBookingId = '';
    }
  },
  updateBookingForEdit(state, data) {
    state.bookingForEdit = data;
  },
};

export default mutations;
